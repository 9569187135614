import { baseURL } from 'components/config';
import Layout from "components/layout/Layout";
import React, { useState, useEffect } from 'react';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path
import { Helmet } from "react-helmet";

const languageCode = sessionStorage.getItem('languageCode');
const inquiry_url = baseURL + '/js/customer-inquiry' ;
const company_url = baseURL + '/js/company?language=' + languageCode;
const home_data_url = baseURL + '/js/home-data?language=' + languageCode;
const contact_photo_css = {
  width: '53px', // Adjust width as needed
  height: '52px',
};

const Contact = () => {
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const [company, setCompany] = useState([]);
  const [core_members, setCoreMembers] = useState([]);

  useEffect(() => {
    fetch(company_url)
      .then((response) => response.json())
      .then((data) => {
        ////console.log(data);
        setCompany(data);
      })
      .catch((err) => {
        ////console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch(home_data_url)
      .then((response) => response.json())
      .then((data) => {
        setCoreMembers(data.core_members);
      })
      .catch((err) => {
        ////console.log(err.message);
      });
  }, []);

  useEffect(() => {
    const form = document.getElementById('contactForm');
    form.addEventListener('submit', function(event) {
      event.preventDefault(); // Prevent the default form submission

      // Collect form data
      const name = document.getElementById('name').value;
      const email = document.getElementById('email').value;
      const phone = document.getElementById('phone').value;
      const message = document.getElementById('message').value;
      const i_type = 'inquiry';

      // Prepare data to be sent
      const data = {
        name: name,
        email: email,
        phone: phone,
        message: message,
        i_type:i_type
      };

      // Send data to the API
      fetch(inquiry_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        ////console.log('Success:', data);
        alert('Your inquiry has been submitted successfully.');
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an error submitting your inquiry.');
      });
    });
  }, []);

  const getSpanList = items => {
    let content = [];
    if (items) {
      content = items.map(item => (
        <span className="new-text" key={item}>{item}</span>
      ));
    }
    return content;
  };

  const getLiImageList = model_list => {
    let content = [];
    if (model_list) {
      content = model_list.map(model => (
        <li key={model.id}>
          <img
            style={contact_photo_css}
            src={model.cover_photo_path}
            alt=""
          />
        </li>
      ));
    }
    return content;
  };

  return (

    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={ <> { getTranslatedWords( translations , 'contact_title') } </> }>



    <Helmet>
          <title>Contat Us</title>
          <meta
            name="description"
            content={ getTranslatedWords( translations , 'contact_title')  || "Contat Us"}
          />
          <meta name="keywords" content={getTranslatedWords( translations , 'contact_title') || "JoinSea Help Center"} />
          <meta property="og:title" content={getTranslatedWords( translations , 'contact_title')} />
          <meta property="og:description" content={getTranslatedWords( translations , 'contact_title')} />
          <meta property="og:image" content='' />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={getTranslatedWords( translations , 'contact_title')} />
          <meta name="twitter:description" content='Contat Us , JoinSea' />
          <meta name="twitter:image" content='' />
    </Helmet>

      <div>
        <section className="contact-area pb-140">
          <div className="container">
            <div className="contact-info-wrap">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-item new-bgc4">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="content">
                      <h2 className="title">{ getTranslatedWords( translations , 'contact_visit_desc') }</h2>
                      <p className="new-text">
                        {company.address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-item new-bgc4">
                    <div className="icon">
                      <i className="fas fa-phone-volume" />
                    </div>
                    <div className="content">
                      <h2 className="title">{ getTranslatedWords( translations , 'contact_tel_desc') } </h2>
                      {getSpanList(company.tel)}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-item new-bgc4">
                    <div className="icon">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="content">
                      <h2 className="title">{ getTranslatedWords( translations , 'contact_email_desc') } </h2>
                      {getSpanList(company.email)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="contact-form-wrap">
                  <h2 className="title">
                    { getTranslatedWords( translations , 'contact_more_questions') }
                  </h2>
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="responds-wrap">
                        <ul className="list-wrap">
                          {getLiImageList(core_members)}
                        </ul>
                        <p className="new-text">{ getTranslatedWords( translations , 'contact_response_time') }</p>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="contact-form">
                        <form id="contactForm" action="#">
                          <div className="form-grp">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder={ getTranslatedWords( translations , 'contact_form_name') }
                              required
                            />
                          </div>
                          <div className="form-grp">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder={ getTranslatedWords( translations , 'contact_form_email') }
                              required
                            />
                          </div>
                          <div className="form-grp">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder={ getTranslatedWords( translations , 'contact_form_tel') }
                              required
                            />
                          </div>
                          <div className="form-grp">
                            <textarea
                              name="message"
                              id="message"
                              placeholder={ getTranslatedWords( translations , 'contact_form_description') }
                              required
                            />
                          </div>
                          <button className="btn" type="submit">
                            { getTranslatedWords( translations , 'contact_form_submit') }
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="contact-shape">
                    <img src="assets/img/images/contact_shape.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact-area-end */}
        {/* contact-map */}
        <div className="contact-map">
          <iframe
            src={ getTranslatedWords( translations , 'google_map_url') }
            allowFullScreen
            loading="lazy"
          />
        </div>
        {/* contact-map-end */}
      </div>
    </Layout>
  );
};

export default Contact;
