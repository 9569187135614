import { baseURL } from 'components/config';
import { Link } from 'react-router-dom'
import Typewriter from 'typewriter-effect'
import ReactHtmlParser from 'react-html-parser';
//rest api
import React, { useState, useEffect } from 'react';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path
import { Helmet } from "react-helmet";

const languageCode = sessionStorage.getItem('languageCode');
const request_content_url = baseURL + '/js/request/home?language=' + languageCode;

const Banner1 = () => {

    const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
    const [content, setContent] = useState([]);
    const [home_title, setHomeTitle] = useState([]);

    const parseAndStripHtmlTags = (html) => {
      if (!html) return "Home"; // Default fallback

      // Parse the HTML if needed
      const parsed = ReactHtmlParser(html);

      // Convert parsed output to string and strip HTML tags
      const plainText =
        typeof parsed === "string"
          ? parsed
          : Array.isArray(parsed)
          ? parsed.map(item => (typeof item === "string" ? item : item?.props?.children || "")).join("")
          : parsed?.toString() || "";

      return plainText.replace(/<\/?[^>]+(>|$)/g, ""); // Remove any residual HTML tags
    };


    useEffect(() => {
      fetch(request_content_url)
         .then((response) => response.json())
         .then((data) => {
            ////console.log(data);
            setContent(data);
            setHomeTitle(data.home_title[0]);
             ////console.log(data.home_title[0]);
         })
         .catch((err) => {
            ////console.log(err.message);
         });
    }, []);

   //rest api
   const [posts, setPosts] = useState([]);
   useEffect(() => {
      fetch(baseURL + '/testData')
         .then((response) => response.json())
         .then((data) => {
            ////console.log(data);
            setPosts(data);
         })
         .catch((err) => {
            ////console.log(err.message);
         });
   }, []);


    return (
        <section className="banner-area">
        <Helmet>
              <title>{parseAndStripHtmlTags(home_title.title) || "Home"}</title>
              <meta
                name="description"
                content={parseAndStripHtmlTags(home_title.title) || "Home"}
              />
              <meta name="keywords" content={parseAndStripHtmlTags(home_title.title) || "Home"} />
              <meta property="og:title" content={parseAndStripHtmlTags(home_title.title)} />
              <meta property="og:description" content={parseAndStripHtmlTags(home_title.description)} />
              <meta property="og:image" content={home_title.cover_photo_path} />
              <meta property="og:url" content={window.location.href} />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={parseAndStripHtmlTags(home_title.title)} />
              <meta name="twitter:description" content={parseAndStripHtmlTags(home_title.description)} />
              <meta name="twitter:image" content={home_title.cover_photo_path} />
        </Helmet>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-content ta-animated-headline">
                                <h2 className="title ah-headline wow fadeInUp" data-wow-delay=".2s">
                                    <span> {ReactHtmlParser(home_title.title)} </span>
                                    <Typewriter tag="span"
                                        options={{
                                            wrapperClassName: "ah-words-wrapper",
                                            strings: home_title.new_hightlighted_text,
                                            autoStart: true,
                                            loop: true,
                                            delay:75,
                                            typeSpeed: 10
                                        }}
                                    />
                                </h2>
                                <h2 className="title d-none wow fadeInUp" data-wow-delay=".2s">{ReactHtmlParser(home_title.title)}  <span></span></h2>
                                <p className="wow fadeInUp new-text" data-wow-delay=".4s">{ReactHtmlParser(home_title.content)}</p>
                                <div className="banner-btn">
                                    <Link to="/blog" className="gradient-btn wow fadeInLeft" data-wow-delay=".6s"> { getTranslatedWords( translations , 'home_page_free_trial') } </Link>
                                    <Link to="/work" className="gradient-btn gradient-btn-two wow fadeInRight" data-wow-delay=".6s"> {getTranslatedWords( translations , 'home_page_hiworks') } </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

    )
}

export default Banner1;