import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Cookies from 'js-cookie'; // Import js-cookie
import { baseURL, contentPageURL } from 'components/config';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path


const languageCode = sessionStorage.getItem('languageCode');

const companyUrl = baseURL + '/js/company?language=' + languageCode;
const homeDataUrl = baseURL + '/js/home-data?language=' + languageCode;
const requestContentUrl = baseURL + '/js/request/footer?language=' + languageCode;
const pageUrl = baseURL + '/js/page-url?language=' + languageCode;

const noUnderlineCss = {
    textDecoration: 'none', /* Remove the underline */
};

const brand_logo_css = {
    height: '80px',
    width: '185px', // Adjust width as needed
    borderRadius: '10%', /* This creates a circular shape, adjust the value to create different curved shapes */
    overflow: 'hidden',/* This ensures the image stays within the curved shape */
};

const join_us_css = {
    marginLeft: '-15px',
};

const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

const Footer1 = () => {
    const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(false);
    const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context

    const getSplitItem = (items) => {
        return items
            ? items.map((item, index) => (
                <span key={item}>
                    {item}
                    {index !== items.length - 1 ? ' , ' : ''}
                </span>
            ))
            : [];
    };

    function getUrlByType(data, name, col_name) {
        return data?.[name]?.[col_name] ?? '';
    }

    function getPageUrlByType(type) {
        return contentPageURL + type;
    }

    const getSocialMediaLinks = (sms) => {
        return sms
            ? (
                <div className="footer-social">
                    <ul className="list-wrap">
                        {Object.values(sms).map((socialMedia) => (
                            <li key={socialMedia.url}>
                                <Link to={socialMedia.url}>
                                    <i className={socialMedia.item} />
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )
            : null; // Return null for an empty content
    };

    const getPartnerships = (data) => {
        let content = [];

        if (data.length !== 0) {
            content.push(
                <Slider {...settings} className="row brand-active" key="partnerships-slider">
                    <h4 className="fw-title">Use Case 22e</h4>
                    {Object.values(data).map((partnership) => (
                        <div className="col" key={partnership.id}>
                            <div className="brand-item">
                                <img src={partnership.cover_photo_path} alt="" />
                            </div>
                        </div>
                    ))}
                    <h4 className="fw-title">Use Case end</h4>
                </Slider>
            );
        }

        return content;
    };

    const getFooterCol = (footer_items, footer_title) => {
        let content = [];

        if (footer_items) {
            content.push(
                <div className="footer-widget" key={footer_title[0].title}>
                    <h4 className="fw-title">{footer_title[0].title}</h4>
                    <div className="footer-link">
                        <ul className="list-wrap">
                            {Object.values(footer_items).map((item) => (
                                <li key={item.more_url}>
                                    <Link to={item.more_url}>{item.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            );
        }

        return content;
    };

    // Rest API
    const [company, setCompany] = useState([]);
    const [pageUrls, setPageUrls] = useState([]);
    const [homeData, setHomeData] = useState([]);
    const [partnerships, setPartnerships] = useState([]);
    const [footers, setFooters] = useState([]);
    const [communityBox, setCommunityBox] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [companyData, pageUrlsData, homeData, footerData] = await Promise.all([
                    fetch(companyUrl).then((response) => response.json()),
                    fetch(pageUrl).then((response) => response.json()),
                    fetch(homeDataUrl).then((response) => response.json()),
                    fetch(requestContentUrl).then((response) => response.json()),
                ]);

                setCompany(companyData);
                setPageUrls(pageUrlsData);
                setHomeData(homeData);
                setPartnerships(homeData.partnerships);
                setFooters(footerData);
                setCommunityBox(footerData.community_box[0]);
            } catch (err) {
                //console.log(err.message);
            }
        };

        fetchData();
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setIsCookieConsentVisible(false);
        }
    }, [companyUrl, pageUrl, homeDataUrl, requestContentUrl]);

    const handleAcceptCookies = () => {
        Cookies.set('cookieConsent', 'true', { expires: 365 }); // Cookie expires in 365 days
        setIsCookieConsentVisible(false);
    };

    return (
        <footer>
            <div className="footer-area">
                <div className="container">
                    {/* Brand-area */}

                    {/* Brand-area-end */}
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                {getFooterCol(footers.first_row, footers.first_row_title)}
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                {getFooterCol(footers.second_row, footers.second_row_title)}
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                {getFooterCol(footers.third_row, footers.third_row_title)}
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="footer-widget st3">
                                    <h4 className="fw-title">{getTranslatedWords(translations, 'footer_lbl_help')}</h4>
                                    <div className="footer-contact">
                                        <Link to={`tel:${company.tel}`} className="phone">
                                            {getSplitItem(company.tel)}
                                        </Link>
                                        <Link to={`mailto:${company.email}`} style={noUnderlineCss} className="email">
                                            {getSplitItem(company.email)}
                                        </Link>
                                        <Link to={company.website} style={noUnderlineCss} className="email">
                                            {company.website}
                                        </Link>
                                    </div>

                                    <Link style={join_us_css} to={communityBox.more_url} className="btn btn-two">
                                        {communityBox.more_text}
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="footer-widget">
                                    <div className="footer-newsletter">
                                        <h6 className="title">{communityBox.title}</h6>
                                        <p>{communityBox.content}</p>
                                        {getSocialMediaLinks(company.social_medias)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="copyright-text">
                                    <p>
                                        {getTranslatedWords(translations, 'footer_lbl_cp_right')} {new Date().getFullYear()} {company.name} {getTranslatedWords(translations, 'footer_lbl_all_right_reserved')}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="footer-menu">
                                    <ul className="list-wrap">
                                        <li>
                                            <Link to={getPageUrlByType('term_and_condition')}>{getUrlByType(pageUrls, 'term_and_condition', 'title')}</Link>
                                        </li>
                                        <li>
                                            <Link to={getPageUrlByType('refund_policy')}>{getUrlByType(pageUrls, 'refund_policy', 'title')}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FloatingWhatsApp
                phoneNumber={getTranslatedWords(translations, 'whatsapp_tel')}
                accountName={getTranslatedWords(translations, 'whatsapp_account_name')}
                chatMessage={getTranslatedWords(translations, 'whatsapp_chat_msg')}
                avatar={getTranslatedWords(translations, 'whatsapp_avatar')}
                chatboxHeight="420px"
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
            {/* Cookie Consent Banner */}
            {isCookieConsentVisible && (
                <div className="cookie-consent-banner">
                    <p>
                        {getTranslatedWords(translations, 'footer_cookie_msg')}
                        <button onClick={handleAcceptCookies} className="btn btn-two">
                            {getTranslatedWords(translations, 'footer_cookie_button_accept')}
                        </button>
                    </p>
                </div>
            )}
        </footer>
    );
};

export default Footer1;
