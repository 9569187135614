import { baseURL } from 'components/config';
import Layout from "components/layout/Layout";
import { Link } from "react-router-dom";
import CountUp, { useCountUp } from "react-countup";
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; 
import { Helmet } from "react-helmet";

const languageCode = sessionStorage.getItem('languageCode');
const home_data_url = baseURL + '/js/home-data?language=' + languageCode;
const company_url = baseURL + '/js/company?language=' + languageCode;
const request_content_url = baseURL + '/js/request/about us?language=' + languageCode;

export default function About() {

  //rest api
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const [home_title, setHomeTitle] = useState([]);
  const [statistic, setStatistic] = useState([]);
  const [introductions, setIntroductions] = useState([]);
  const [introduction_title, setIntroductionTitle] = useState([]);
  const [statistic_title, setStatisticTitle] = useState([]);
  useEffect(() => {
      fetch(request_content_url)
         .then((response) => response.json())
         .then((data) => {
          //console.log(data);
            setHomeTitle(data.home_title[0]);
            setIntroductionTitle(data.introduction_title[0]);
            setStatisticTitle(data.statistic_title[0]);
            setStatistic(data.statistic);
            setIntroductions(data.introduction);
         })
         .catch((err) => {
            //console.log(err.message);
         });
  }, []);

  const [home_data, setHomeData] = useState([]);
  const [core_members, setCoreMembers] = useState([]);
  useEffect(() => {
    fetch(home_data_url)
       .then((response) => response.json())
       .then((data) => {
          //console.log(data);
          setHomeData(data);
          setCoreMembers(data.core_members);
       })
       .catch((err) => {
          //console.log(err.message);
       });
  }, []);

  const [company, setCompany] = useState([]);
   useEffect(() => {
      fetch(company_url)
         .then((response) => response.json())
         .then((data) => {
            //console.log(data);
            setCompany(data);
         })
         .catch((err) => {
            //console.log(err.message);
         });
   }, []);


  const getStatisticBoxes = statistics => {
    let content = [];
    content.push(
        <div className="counter-item-wrap-three">
          <ul className="list-wrap">
              {Object.keys(statistics).map(key => (
                    <li>
                      <div className="counter-item-three">
                        <h2 className="count">
                          <CountUp
                            end={statistic[key].content}
                            suffix=""
                            duration={5}
                            enableScrollSpy
                            scrollSpyDelay={5}
                          />
                        </h2>
                        <p className='new-text'>{statistics[key].title}</p>
                      </div>
                    </li>
               ))}
        </ul>
      </div>
    );

    return content;
  };


const getIntroductionItems = introductions => {
    let content = [];
    content.push(
        <ul className="list-wrap">
              {Object.keys(introductions).map(key => (
                    <li>
                      <i className="fas fa-check new" />
                       <p className='new-text'>{introductions[key].content}</p>
                    </li>
               ))}
        </ul>
    );

    return content;
  };


const getSocialMediaLinks = social_medias => {
  let content = [];

  for (let i = 0; i < social_medias.length; i++) {
    const social_media = social_medias[i];
    content.push(
      <li>
        <Link to={social_media.url}>
          <i className={social_media.item} />
        </Link>
      </li>
    )
  }

  return content;
};


  const getOuterLayer = layer => {
    let content = 'team-item '+{layer};

    return content;
  };

  return (

    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={
        <>
         {ReactHtmlParser(home_title.title)}
        </>
      }
    >

    <Helmet>
          <title>{home_title.title || "About Us"}</title>
          <meta
            name="description"
            content={introduction_title.title || "About JoinSea"}
          />
          <meta name="keywords" content={home_title.title || "blog, article"} />
          <meta property="og:title" content={home_title.title} />
          <meta property="og:description" content={introduction_title.title} />
          <meta property="og:image" content={home_title.cover_photo_path} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={home_title.title} />
          <meta name="twitter:description" content={introduction_title.title} />
          <meta name="twitter:image" content={home_title.cover_photo_path} />
    </Helmet>

      <div>
        {/* about-area */}
        <section className="inner-about-area pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-about-img">
                  <img src={home_title.cover_photo_path} alt="" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="inner-about-content">
                  <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                     {ReactHtmlParser(introduction_title.title)}
                  </h2>
                  <div className="content-bottom">
                    <p className='new-text'>
                       {ReactHtmlParser(introduction_title.content)}
                    </p>
                    { getIntroductionItems(introductions) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* about-area-end */}
        {/* team-area */}
        <section className="team-area pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-four text-center mb-60">
                  <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                    { getTranslatedWords( translations , 'about_core_member_title') }
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
                    {core_members.map((core_member) => {
                       return (
                          <div className={core_member.col_class}>
                              <div className={`team-item${core_member.layer}`}>
                                <div className={`team-thumb`}>
                                  <img src={core_member.cover_photo_path} alt="" />
                                  <div className={`team-hidden-content${core_member.layer}`}>
                                    <h4 className="title">{core_member.name}</h4>
                                    <p>
                                      {core_member.content}
                                    </p>
                                  </div>
                                </div>
                                <div className="team-content">
                                  <div className="team-info">
                                    <h4 className="title">{core_member.name}</h4>
                                    <span className='new-text'>{core_member.position}</span>
                                  </div>
                                  <div className="team-social">
                                      <ul className="list-wrap">
                                          {getSocialMediaLinks(core_member.social_medias)}
                                      </ul>
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                       );
                    })}
            </div>
          </div>
        </section>
        {/* team-area-end */}
        {/* counter-area */}
        <section className="counter-area-three pb-160">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="counter-content">
                  <div className="section-title-four mb-40">
                    <h2 className="title wow fadeInLeft" data-wow-delay=".2s">
                      {ReactHtmlParser(statistic_title.title)}
                    </h2>
                  </div>
                  <p className='new-text'>
                    {ReactHtmlParser(statistic_title.content)}
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                    { getStatisticBoxes(statistic) }
              </div>
            </div>
          </div>
        </section>
        {/* counter-area-end */}
      </div>
    </Layout>
  );
}
