import { baseURL } from 'components/config';
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from 'global_providers/TranslationProvider'; // Adjust the import path

function MenuMobile({ handleCloseMenu }) {
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const { pathname } = useLocation();
  const [current, setCurrent] = useState("home1");

  const [toggles, setToggles] = useState({
    "our-service": false,
    "join-seller": false,
    "join-tech" : false,
    "join-3pl" : false,
    "join-enabler" : false,
  });

  useEffect(() => {
    if (pathname) {
      let activePath = pathname.slice(1, pathname.length);
      setCurrent(activePath ? activePath : "home1");
    }
  }, [pathname]);

  const handleCurrent = useCallback(
    (param = "") => {
      setCurrent(param);
    },
    [current]
  );

  const checkActive = (param) => {
    //console.log('Current  :' + current);
    //console.log('Param  :' + param);
    if (param === "pages") {
      switch (current) {
        case "pages":
        case "login":
        case "404": {
          return true;
        }
        default:
          return false;
      }
    } else if ( param === "join-seller") {
      return ["product-services/ec_store_mangement"].includes(current);
    
    } else if ( param === "join-tech") {
      return ["product-services/software_tech",  "product-details/digital", "product-details/unmaned"].includes(current);
    
    } else if ( param === "join-3pl" ) {
      return ["product-services/warehousing"].includes(current);
    
    } else if ( param === "join-enabler") {
      return ["product-services/cc_affiliate_marketing"].includes(current);
    
    } else if (param === "about-us") {
      switch (current) {
        case "about_pages":
        case "about":
        case "faq":
        case "job-details":
        case "job": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "news") {
      switch (current) {
        case "blog":
        case "blog-details": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "e_pages") {
      switch (current) {
        case "e_pages": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "home") {
      switch (current) {
        case "home1": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "success_story") {
      switch (current) {
        case "success_story": {
          return true;
        }
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  const handleToggle = (key) => {
    setToggles((prevToggles) => ({
      ...prevToggles,
      [key]: !prevToggles[key],
    }));
  };

  return (
    <ul className="navigation">
      <li className={`${checkActive("home") ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`}>
        <Link to="/"> { getTranslatedWords( translations , 'h_menu_home') }</Link>
      </li>

      <li className={`${checkActive("our-service") ? "active" : ""} menu-item-has-children`}>
        <h5 onClick={() => {handleToggle("our-service");}}>
          { getTranslatedWords( translations , 'h_menu_s_pages') }
        </h5>
        <Collapse isOpened={toggles["our-service"]}>
          <ul className="sub-menu">
           
            <li
              className={`${checkActive("join-seller") ? "active" : ""} `} style={{ paddingLeft: "20px" }}
              onClick={() => handleToggle("join-seller")}
            >
              <h5>{getTranslatedWords(translations, "header_j_seller")}</h5>
              <Collapse isOpened={toggles["join-seller"]}>
                <ul className="sub-menu">
                      
                      <li className={`${current === "product-services/ec_store_mangement" ? "active" : ""} menusub-link`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCurrent("product-services/ec_store_mangement");
                          handleCloseMenu();
                          handleToggle("our-service");
                        }}
                      >
                        <Link to="/product-services/ec_store_mangement"> { getTranslatedWords( translations , 'h_menu_ec_store_mangement') }</Link>
                      </li>
                </ul>
              </Collapse>
            </li>

            <li
              className={`${checkActive("join-3pl") ? "active" : ""}`} style={{ paddingLeft: "20px" }}
              onClick={() => handleToggle("join-3pl")}
            >
              <h5>{getTranslatedWords(translations, "header_j_3pl")}</h5>
              <Collapse isOpened={toggles["join-3pl"]}>
                <ul className="sub-menu">
                  <li
                    className={`${current === "product-services/warehousing" ? "active" : ""} menusub-link`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCurrent("product-services/warehousing");
                      handleCloseMenu();
                      handleToggle("our-service");
                    }}
                  >
                    <Link to="/product-services/warehousing">
                      {getTranslatedWords(translations, "h_menu_warehousing")}
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>


            <li
              className={`${checkActive("join-tech") ? "active" : ""}`} style={{ paddingLeft: "20px" }}
              onClick={() => handleToggle("join-tech")}
            >
              <h5>{getTranslatedWords(translations, "header_j_tech")}</h5>
              <Collapse isOpened={toggles["join-tech"]}>
                <ul className="sub-menu">
                      
                      <li className={`${current === "product-details/digital" ? "active" : ""} menusub-link`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCurrent("product-details/digital");
                          handleCloseMenu();
                          handleToggle("our-service");
                        }}
                      >
                        <Link to="/product-details/digital"> { getTranslatedWords( translations , 'h_menu_digital') }</Link>
                      </li>


                      <li className={`${current === "product-details/unmaned" ? "active" : ""} menusub-link`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCurrent("product-details/unmaned");
                          handleCloseMenu();
                          handleToggle("our-service");
                        }}
                      >
                        <Link to="/product-details/unmaned"> { getTranslatedWords( translations , 'h_menu_unmaned') }</Link>
                      </li>


                      <li
                        className={`${current === "product-services/software_tech" ? "active" : ""} menusub-link`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCurrent("product-services/software_tech");
                          handleCloseMenu();
                          handleToggle("our-service");
                        }}
                      >
                        <Link to="/product-services/software_tech">
                          {getTranslatedWords(translations, "h_menu_software_tech")}
                        </Link>
                      </li>


                </ul>
              </Collapse>
            </li>

            <li
              className={`${checkActive("join-enabler") ? "active" : ""}`} style={{ paddingLeft: "20px" }}
              onClick={() => handleToggle("join-enabler")}
            >
              <h5>{getTranslatedWords(translations, "header_j_enabler")}</h5>
              <Collapse isOpened={toggles["join-enabler"]}>
                <ul className="sub-menu">
                    <li className={`${current === "product-services/cc_affiliate_marketing" ? "active" : ""} menusub-link`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCurrent("product-services/cc_affiliate_marketing");
                          handleCloseMenu();
                          handleToggle("our-service");
                        }}
                      >
                        <Link to="/product-services/cc_affiliate_marketing"> { getTranslatedWords( translations , 'h_menu_cc_affiliate_marketing') } </Link>
                      </li>
                </ul>
              </Collapse>
            </li>
        
            

          </ul>
        </Collapse>

        <div className="dropdown-btn" onClick={() => {handleToggle("our-service");}}>
          <span className="fas fa-angle-down" />
        </div>
      </li>

      <li className={`${current === "blog" ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <Link to="/blog"> { getTranslatedWords( translations , 'news_title') } </Link>
      </li>

     {/* <li className={`${current === "success_story" ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <Link to="/blog"> { getTranslatedWords( translations , 'h_menu_sStory') }</Link>
      </li>*/}

      <li className={`${current === "contact" ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <Link to="/contact"> { getTranslatedWords( translations , 'h_menu_contact') } </Link>
      </li>

      <li className={`${checkActive("about-us") ? "active" : ""} menu-item-has-children`}>
        <h5 onClick={() => {handleToggle("about-us");}}>
          { getTranslatedWords( translations , 'h_menu_about') }
        </h5>

        <Collapse isOpened={toggles["about-us"]}>
          <ul className="sub-menu">
            <li
              className={`${current === "help" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("help");
                handleCloseMenu();
                handleToggle("about-us");
              }}
            >
              <Link to="/help"> {getTranslatedWords(translations, "h_menu_help")}</Link>
            </li>

            <li
              className={`${current === "about" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("about-us");
                handleCloseMenu();
                handleToggle("about-us");
              }}
            >
              <Link to="/about"> {getTranslatedWords(translations, "h_menu_about")} </Link>
            </li>

            <li
              className={`${current === "job" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("job");
                handleCloseMenu();
                handleToggle("about-us");
              }}
            >
              <Link to="/job"> {getTranslatedWords(translations, "h_menu_job")} </Link>
            </li>
          </ul>
        </Collapse>


        <div className="dropdown-btn" onClick={() => {handleToggle("about-us");}}>
          <span className="fas fa-angle-down" />
        </div>
      </li>
    </ul>
  );
}

export default MenuMobile;
