import { baseURL } from 'components/config';
import Layout from "components/layout/Layout";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Pricing2 from "components/layout/pricing/Pricing2";
//import Compare from "components/layout/compare/Compare";
import Cta from "components/layout/cta/Cta";
import Language from "components/layout/language/Language";
import TestimonySection from "components/layout/language/TestimonySection";
import { useLocation } from 'react-router-dom';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path
import { Helmet } from "react-helmet";

const margin_top = {
       marginTop: '5%', // Adjust width as needed
    };


const cta_padding_bottom = {
       paddingBottom: '5%', // Adjust width as needed
    };


const margin_bottom = {
       paddingBottom: '9.5%', // Adjust width as needed
    };

const logo_photo_css = {
       width: '100%', // Adjust width as needed
       height: '100px', 
    };

const feature_listing_css = {
       height: '250px', 
    };


const getIntroductionItems = introductions => {
  let content = [];
  content.push(
      <ul className="list-wrap">
            {Object.keys(introductions).map(key => (
                  <li>
                    <i className="fas fa-check" />
                     <p className="new-text">{introductions[key].content}</p>
                  </li>
             ))}
      </ul>
  );

  return content;
};

const ProductServices = () => {

  const location = useLocation();
  const currentUrl = location.pathname;
  const inquiryUrl = `/login?inquiry_type=${encodeURIComponent(currentUrl)}`;
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const languageCode = sessionStorage.getItem('languageCode');
  const [home_title, setHomeTitle] = useState([]);
  const { id } = useParams();
  const product_service_url = `${baseURL}/js/product-service/${id}` + '?language=' + languageCode;


  const [statistic, setStatistic] = useState([]);
  const [steps, setSteps] = useState([]);
  const [step_title, setStepTitle] = useState([]);
  const [introductions, setIntroductions] = useState([]);
  const [introduction_title, setIntroductionTitle] = useState([]);
  const [statistic_title, setStatisticTitle] = useState([]);
  const [features, setFeatures] = useState([]);
  const [feature_title, setFeatureTitle] = useState([]);
  const [contact_us_title, setContactUsTitle] = useState([]);

  //check items 
  const [label_listings, setLabelListings] = useState([]);
  const [testimony, setTestimony] = useState([]);

  const product_compare_url = `${baseURL}/js/product-compare/${id}` + '?language=' + languageCode;
  const [first_item, setFirstItem] = useState([]);
  const [second_item, setSecondItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isToggled, setToggled] = useState(false);
    const toggleTrueFalse = () => setToggled(!isToggled);
 
  useEffect(() => {
  // Fetch product detail data
  fetch(product_service_url)
    .then((response) => response.json())
    .then((data) => {
      //console.log('Warehusing');
      //console.log(product_service_url);
      //console.log(data);
      setLabelListings(data.label_listing);

      //console.log('retrieve :');
      //console.log(label_listings);
      //console.log('End');
      setHomeTitle(data.home_title[0]);
      //setStepTitle(data.step_title[0]);
      setIntroductionTitle(data.introduction_title[0]);
      setFeatureTitle(data.feature_title[0]);
      setContactUsTitle(data.contact_us_title[0]);

      //setSteps(data.step);
      //setFeatures(data.testimony_section[0]);

      setFeatures(data.feature);
      setIntroductions(data.introduction);
      setIntroductionTitle(data.introduction_title[0]);
      //check items
      
      //setStatisticTitle(data.statistic_title[0]);
      //setStatistic(data.statistic);
      setLoading(false); 
      //console.log('end of set data');
    })
    .catch((err) => {
      //console.log(err.message);
    });

  // Fetch product compare data
  /*fetch(product_compare_url)
    .then((response) => response.json())
    .then((data) => {
      //console.log(product_compare_url);
      //console.log(data);
      setFirstItem(data.products[0]);
      setSecondItem(data.products[1]);
      //console.log('Check list');
      //console.log(data.products[0]);
      //console.log(data.products[1]);
      setLoading(false); // Set loading to false when data is fetched
    })
    .catch((err) => {
      //console.log(err.message);
      setLoading(false); // Set loading to false on error as well
    });*/
}, [product_service_url]);
 

// Example usage:

////console.log(getContentListing(items));

 
const getContentListing = (items) => {
  let content = [];
  //console.log('Generate listing');
  ////console.log(items);
  // Check if 'item' and 'item.title' are defined
  content = items.map((item, index) => (
    <div key={index} className={`col-lg-${item.col || 4} col-md-10`}>
      <div className="compare-item">
        <div className="compare-thumb">
          {/* Check if 'item.cover_photo_path' is defined */}
          <img src={item.cover_photo_path || ''} alt="" />
        </div>
        <div className="compare-content">
          <h3 className="title">{item.title || ''}</h3>
          <div className="compare-list">
            { ReactHtmlParser(item.content) || ''}
          </div>
        </div>
      </div>
    </div>
  ));

  return content;
};


  return (
    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={
        <>
         {ReactHtmlParser(home_title.title)}
        </>
      }
    >

    <Helmet>
          <title>{ introduction_title.title  || "JoinSea Products"}</title>
          <meta
            name="description"
            content={ introduction_title.title  || "JoinSea Products"}
          />
          <meta name="keywords" content={introduction_title.title || "JoinSea Products"} />
          <meta property="og:title" content={introduction_title.title} />
          <meta property="og:description" content={introduction_title.title} />
          <meta property="og:image" content='' />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={introduction_title.title} />
          <meta name="twitter:description" content='{ introduction_title.title  || "JoinSea Products"}' />
          <meta name="twitter:image" content='' />
    </Helmet>

      <div>
        {/* about-area */}
        <section className="inner-about-area pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-about-img">
                  <img src={home_title.cover_photo_path} alt="" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="inner-about-content">
                  <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                     {ReactHtmlParser(introduction_title.title)}
                  </h2>
                  <div className="content-bottom">
                    <p className="new-text">
                       {ReactHtmlParser(introduction_title.content)}
                    </p>
                    { getIntroductionItems(introductions) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* about-area-end */}



        {/* Compare section */}
           <section className="compare-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8">
            <div className="section-title-two text-center mb-70">
              <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                { getTranslatedWords( translations , 'ps_ec_store_management_listing_title') }
                <span>
               
                  <strong>
                    { getTranslatedWords( translations , 'ps_ec_store_management_listing_title_spText') }
                    <svg
                      viewBox="0 0 345 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M344.366 0.00527191C231.51 16.2892 117.803 18.0623 5.82257 7.9456C3.59842 7.74418 0.292572 9.4891 0.0174561 12.1809C-0.260559 14.9221 2.83823 17.0748 5.06818 17.301C117.89 28.79 231.159 22.6085 344.545 1.4609C345.266 1.32606 345.074 -0.0971813 344.366 0.00527191Z"
                        fill="url(#compare)"
                      />
                      <defs>
                        <linearGradient
                          id="compare"
                          x1="376.061"
                          y1="0.000463246"
                          x2="10.2776"
                          y2="101.79"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0.0361276" stopColor="#FAEC60" />
                          <stop offset="0.159651" stopColor="#E5A34B" />
                          <stop offset="0.269837" stopColor="#EE6E4D" />
                          <stop offset="0.42316" stopColor="#F44380" />
                          <stop offset="0.55979" stopColor="#BE3DB3" />
                          <stop offset="0.745252" stopColor="#7746E6" />
                          <stop offset="0.888441" stopColor="#5A71F1" />
                          <stop offset={1} stopColor="#439EFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </strong>
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <React.Fragment>
              {getContentListing(label_listings)}
            </React.Fragment>
          )}
        </div>
      </div>
      {/*<div className="compare-shape-wrap">
        <img
          src="/assets/img/images/compare_shape01.png"
          alt=""
          data-aos="fade-left"
          data-aos-delay={300}
        />
        <img
          src="/assets/img/images/compare_shape02.png"
          alt=""
          data-aos="fade-right"
          data-aos-delay={300}
        />
      </div>*/}
    </section>
        {/* Compare section */}


          <section style={margin_top} className="writing-area pb-130">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb-80">
                  <h2
                    className="title title-animation wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    {ReactHtmlParser(feature_title.title)}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
{/*<div className="writing-icon" bis_skin_checked="1" style="width:135px;height:135px;margin-left:-50px;margin-top:-50px;">
<i className=""><img src="https://review.thaiware.com/upload_misc/review/2019_09/images/1598_190904172228H5_14.jpg" style="width:15px;height:15px;"></i>
</div>
          */}
              {Object.keys(features).map(key => (
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-9">
                        <div className="writing-item">
                          <div className="writing-shape">
                            <svg
                              viewBox="0 0 417 207"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              preserveAspectRatio="none"
                            >
                              <g opacity="0.3">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 96C0 82 7.5 73 26.2229 76.38C43.8225 79.5573 73.5 62.5 65 34C58.0931 10.8417 68.4854 0.0152226 90.4536 0H387C403.569 0 417 13.4315 417 30V177C417 193.569 403.569 207 387 207H30C13.4315 207 0 193.569 0 177V96Z"
                                  fill="currentcolor"
                                />
                                <path
                                  d="M26.2229 76.38L26.0452 77.3641L26.0452 77.3641L26.2229 76.38ZM65 34L64.0417 34.2858L65 34ZM90.4536 0L90.4536 -1L90.4529 -1L90.4536 0ZM26.4005 75.3959C16.8849 73.6781 9.9765 75.0628 5.4433 78.9101C0.915622 82.7526 -1 88.8465 -1 96H1C1 89.1535 2.83438 83.7474 6.73743 80.4349C10.6349 77.1272 16.838 75.7019 26.0452 77.3641L26.4005 75.3959ZM64.0417 34.2858C68.1618 48.1001 63.0533 59.0984 54.7432 66.3139C46.3758 73.5791 34.8545 76.9221 26.4005 75.3959L26.0452 77.3641C35.1909 79.0152 47.3082 75.4182 56.0544 67.8241C64.858 60.1802 70.3382 48.3998 65.9583 33.7142L64.0417 34.2858ZM90.4529 -1C79.3517 -0.992307 70.8799 1.74143 66.1176 7.69682C61.3388 13.673 60.5475 22.57 64.0417 34.2858L65.9583 33.7142C62.5456 22.2717 63.4971 14.1764 67.6796 8.94589C71.8788 3.69466 79.5873 1.00753 90.4543 1L90.4529 -1ZM90.4536 1H387V-1H90.4536V1ZM387 1C403.016 1 416 13.9837 416 30H418C418 12.8792 404.121 -1 387 -1V1ZM416 30V177H418V30H416ZM416 177C416 193.016 403.016 206 387 206V208C404.121 208 418 194.121 418 177H416ZM387 206H30V208H387V206ZM30 206C13.9837 206 1 193.016 1 177H-1C-1 194.121 12.8792 208 30 208V206ZM1 177V96H-1V177H1Z"
                                  fill="currentcolor"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="writing-icon">
                            <i className={features[key].icon}></i>
                          </div>
                          <div className="writing-content">
                            <h4 className="title">{features[key].title}</h4>
                            <p className="new-text">
                              { ReactHtmlParser(features[key].content)}
                            </p>
                          </div>
                        </div>
                    </div>
               ))}
            </div>
          </div>
        </section>

        {/*Pricing section*/}
   
        {/*Pricing section end*/}
           <TestimonySection />

      
          
        
            <section style={cta_padding_bottom} className="">
                <div className="container">
                    <div className="">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                                <div className="cta-content">
                                    <h2 className="title title-animation"> {contact_us_title.title} </h2>
                                    <p>
                                       {contact_us_title.content}
                                    </p>
                                    <Link to={inquiryUrl} className="btn">{ getTranslatedWords( translations , 'ps_contact_us_label') } </Link>
                                </div>
                            </div>
                        </div>
                       {/* <div className="cta-shape-wrap">
                            <img src="/assets/img/images/cta_shape01.png" alt="" className="wow fadeInLeft" data-wow-delay=".2s" />
                            <img src="/assets/img/images/cta_shape02.png" alt="" className="wow fadeInRight" data-wow-delay=".2s" />
                        </div>*/}
                    </div>
                </div>
            </section>


      </div>
    </Layout>
  );
};
export default ProductServices;
